import { useMsal } from "@azure/msal-react";
import { isDevEnv } from "config/env";
import { useCallback, useEffect, useMemo } from "react";
import { hotjar } from "react-hotjar";
import useSWR from "swr";

export type PermissionSection =
  | "Admin"
  | "Cost"
  | "CustomerService"
  | "DailyStatus"
  | "Forecast"
  | "Inventory"
  | "Order"
  | "Quality"
  | "User";
export type Permission = "Create" | "Read" | "Update" | "Delete";
export type Mapping = { [key: string]: any };

export interface UserResponse {
  id: string;
  name: string;
  email: string;
  roles: string[];
  modules: {
    name: PermissionSection;
    permissions: {
      name: Permission;
      value: boolean;
    }[];
  }[];
  connectedCustomers: string[];
  favoriteCustomers: string[];
  startPage: string | null;
}

export const mapPermissionsToSection = (permissions: UserResponse) => {
  const result = permissions.modules.reduce((acc, current) => {
    acc[current.name] = current.permissions.reduce((acc, current) => {
      acc[current.name] = current.value;
      return acc;
    }, {} as Mapping);
    return acc;
  }, {} as Mapping);
  return result;
};

export const hasPermissionForSection = (
  section: string,
  permission: Permission,
  permissionsBySection: Mapping,
) => {
  return (
    permissionsBySection[section] && permissionsBySection[section][permission]
  );
};

export const getDefaultPathForPermissions = (
  permissionsForSection: Mapping,
) => {
  const routes = [
    { section: "Cost", path: "/cost-control/cost" },
    { section: "Order", path: `/cost-control/order` },
    { section: "Inventory", path: `/cost-control/inventory` },
    { section: "Freight", path: `/cost-control/freights` },
    { section: "DailyStatus", path: `/daily-status` },
    { section: "Forecast", path: `/forecast` },
    { section: "Quality", path: `/quality` },
    { section: "CustomerService", path: "/customer-service/help-center" },
    { section: "Admin", path: `/admin/customers` },
    { section: "Users", path: `/users` },
  ];
  const matching = routes.find((route) =>
    hasPermissionForSection(route.section, "Read", permissionsForSection),
  );
  return matching?.path ?? false;
};

export const useUser = () => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const {
    data: user,
    isLoading,
    mutate,
  } = useSWR<UserResponse>(account?.localAccountId ? `/Users/me` : null);

  const permissionsBySection = useMemo(() => {
    if (!user) return {};
    return mapPermissionsToSection(user);
  }, [user]);

  useEffect(() => {
    if (user && isDevEnv) {
      if (!hotjar.initialized()) {
        hotjar.initialize(3514786, 6);
      }
      hotjar.identify(user.id, {
        name: user.name,
        email: user.email,
      });
    }
  }, [user]);

  const handleHasPermissionForSection = useCallback(
    (section: string, permissions: Permission) =>
      hasPermissionForSection(section, permissions, permissionsBySection),
    [permissionsBySection],
  );

  return {
    account,
    permissionsBySection,
    user,
    mutate,
    isLoading,
    hasPermissionForSection: handleHasPermissionForSection,
  };
};
